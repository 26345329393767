export default [
    { src: "portfolio/113 Euclid St-2.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-6.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-20.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-25.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-27.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-33.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-37.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-45.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-51.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/113 Euclid St-56.jpg", alt: "113 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-3.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-6.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-17.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-19.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-25.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-27.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-31.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-37.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-51.jpg", alt: "115 Euclid St., Whitby, ON" },
    { src: "portfolio/115 EUCLID ST-54.jpg", alt: "115 Euclid St., Whitby, ON" }
]