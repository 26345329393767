import * as React from "react"
import { Helmet } from 'react-helmet';
import { StaticImage } from "gatsby-plugin-image";
import SocialLinks from "../components/social-links";
import portfolio from "../portfolio";
import { Masonry } from "masonic";

import '../styles/index.css';

let i = 0;

// build masonry style portfolio gallery
const galleryItems = portfolio.map((image) => ({
  id: i++,
  name: image.alt,
  src: image.src
}));


const galleryCard = ({ data: { id, src, name }, width, index }) => {
  const imageSrc = JSON.parse(JSON.stringify(src));
  return (
    <div className="galleryCard" style={{width: width}}>
      <img src={src} alt={name} />
    </div>
  );
}



// markup
const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>The Loën Group: Custom Home Builders in Durham Region, ON.</title>
        <meta name="description" content="The Loën Group, custom home builders in Durham Region, ON." />
      </Helmet>

      <div id="logoHeader">
        <StaticImage 
            src="../images/loengroup-logo-horizontal.png" 
            alt="header logo" 
            width={650} 
            className="logo"
        />
      </div>

      <div id="prevWorkBanner">
        <StaticImage src="../images/previous_work_banner/Ajax House.png" alt="Pickering, ON residence" />  
        <StaticImage src="../images/previous_work_banner/113 Euclid St-3.jpg" alt="113 Euclid St., Whitby, ON" />
        <StaticImage src="../images/previous_work_banner/115 EUCLID ST-4.jpg" alt="115 Euclid St., Whitby, ON" />
        <StaticImage src="../images/previous_work_banner/Newcastle House.png" alt="Newcastle, ON residence" />
      </div>

      <section id="introStatement">
        <p><span className="bigCap">O</span>ur company has been built on professionalism, pride, and reliability.</p>
        <p>We specialize in any type of construction, creative design and project management.</p>
        <p>Project requests include anything from new construction to renovation, decks and fences, to street scaping: no job is too big for <span className="TLG">The Loën Group</span>.</p>
        <p>Complete satisfaction is always our goal, so no matter what the circumstance <span className="TLG">The Loën Group</span> can get your job done.</p>
      </section>

      <section>
        <p>Contact us today via email, or reach out on social media where we post updates on our latest work.</p>    
      </section>

      <SocialLinks />

      <section>
        <h2>42 Park Lane, Bond Head, Newcastle, ON.</h2>
        <p>Currently under construction, our latest build is making steady progress towards Summer 2021 completion. </p>
        <p>Visit <a href="https://42parklane.ca">https://42parklane.ca</a> for details, PDF floorplans, asking price and progress photos.</p>
        <a href="https://42parklane.ca" className="siteThumb">
          <StaticImage src="../images/42parklane-ca_THUMB.png" alt="42parklane.ca thumbnail" width={800} />
        </a>
      </section>

      <section>
        <h2>Recently completed work, Durham Region, ON.</h2>
        <Masonry
          items={galleryItems}
          render={galleryCard}
        />
      </section>

      <section>
        <p style={{ textAlign: "center", opacity: "0.5" }}><small>Copyright ©2022 The Loën Group.</small></p>
      </section> 
    </main>
  )
}

export default IndexPage
