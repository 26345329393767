import React from "react";

import SvgFacebook from "./svg/facebook_logo.js";
import SvgTwitter from "./svg/twitter_logo.js";
import SvgInstagram from "./svg/instagram_logo.js";

export default function SocialLinks() {
    return (
        <>
          <div className="send_email"><a href="mailto:info@theloengroup.ca">info@theloengroup.ca</a></div>
          <div className="social_links">
              <a href="https://facebook.com/loengroup"><SvgFacebook width='50px' height='50px' fill='white' /><br/>Facebook</a>
              <a href="https://twitter.com/loengroup"><SvgTwitter width='50px' height='50px' fill='white' /><br/>Twitter</a>
              <a href="https://instagram.com/loengroup"><SvgInstagram width='50px' height='50px' fill='white' /><br/>Instagram</a>
          </div>
        </>
    )
}